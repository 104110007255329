import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";

const PARTNER = gql`
  query getPartner($id: ID) {
    getPartner(id: $id) {
      id
      firstName
      lastName
      email
      address
      tel
      country {
        id
        name
      }
      ibanUSD
      ibanEUR
      ibanTL
      label
      swiftCode
      bankName
      blocked
    }
  }
`;
const ADD_PARTNER = gql`
  mutation addPartner($input: PartnerUpdateInput) {
    addPartner(input: $input) {
      id
      firstName
      lastName
      email
      address
      tel
      country {
        id
        name
      }
      ibanUSD
      label
      ibanEUR
      ibanTL
      swiftCode
      bankName
      blocked
    }
  }
`;
const UPDATE_PARTNER = gql`
  mutation updatePartner($id: ID!, $input: PartnerUpdateInput) {
    updatePartner(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      address
      tel
      country {
        id
        name
      }
      ibanUSD
      label
      ibanEUR
      ibanTL
      swiftCode
      bankName
      blocked
    }
  }
`;
const REMOVE_PARTNER = gql`
  mutation removePartner($id: String) {
    removePartner(id: $id)
  }
`;
const BLOCK_PARTNER = gql`
  mutation blockPartner($id: String, $blocked: Boolean) {
    blockPartner(id: $id, blocked: $blocked)
  }
`;
export const useMutationPartner = () => {
  const [update] = useMutation(UPDATE_PARTNER);
  const [add] = useMutation(ADD_PARTNER);
  const [block] = useMutation(BLOCK_PARTNER);
  const [remove] = useMutation(REMOVE_PARTNER);

  function updatePartner({ id, input }) {
    return update({ variables: { id, input }, fetchPolicy: "no-cache" });
  }

  function addPartner({ input }) {
    return add({ variables: { input }, fetchPolicy: "no-cache" });
  }
  function blockPartner({ id, blocked }) {
    return block({ variables: { id, blocked }, fetchPolicy: "no-cache" });
  }
  function removePartner({ id }) {
    return remove({ variables: { id }, fetchPolicy: "no-cache" });
  }
  return { updatePartner, addPartner, blockPartner, removePartner };
};

export const useQueryPartner = (id) => {
  const { data, ...rest } = useQuery(PARTNER, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  return { data: data?.getPartner, ...rest };
};
