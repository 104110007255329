import jwtDecode from "jwt-decode";

class Token {
  token = null;

  constructor() {
    this.init();
  }

  init() {
    this.token = localStorage.getItem("token");
  }

  getToken = () => this.token;

  setToken = (token) => (this.token = token);

  getId = () => {
    try {
      if (!this.token) {
        return null;
      } else {
        const { id } = jwtDecode(this.token);
        return id;
      }
    } catch (err) {
      console.log(err);
    }
  };
  getRole = () => {
    try {
      if (!this.token) {
        if (!localStorage.getItem("token")) return null;
      }
      const { role } = jwtDecode(this.token || localStorage.getItem("token"));
      return role;
    } catch (err) {
      console.log(err);
    }
  };
  getStoreId = () => {
    try {
      if (!this.token) {
        return null;
      }

      const { storeId } = jwtDecode(this.token);
      return storeId;
    } catch (err) {
      console.log(err);
    }
  };
  isFrame = () => {
    try {
      if (!this.token) {
        return null;
      }
      const { isFrame } = jwtDecode(this.token);
      return isFrame;
    } catch (err) {}
  };
}

export default new Token();
