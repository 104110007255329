import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import {
  useMutationNotifcation,
  useNotificationQuery,
} from "hooks/notification/notification.hooks";
import moment from "moment";
import { useTranslate } from "utility/context/Language";
import { Link } from "react-router-dom";

const NotificationItem = ({ title, message, kind, createdAt }) => {
  // AlertTriangle ,PlusSquare
  const ComponentIcon =
    kind === "warning" || kind === "danger"
      ? Icon.AlertTriangle
      : Icon.PlusSquare;
  return (
    <div className="d-flex justify-content-between">
      <Media className="d-flex align-items-start">
        <Media left href="#">
          <ComponentIcon className={`font-medium-5 ${kind}`} size={21} />
        </Media>
        <Media body>
          <Media heading className={`${kind} media-heading`} tag="h6">
            {title ? title : "Info"}
          </Media>
          <p className="notification-text">{message}</p>
        </Media>
        <small>
          <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
            {moment(createdAt).locale("en").fromNow()}
          </time>
        </small>
      </Media>
    </div>
  );
};

export default function Notification() {
  const { notifications, inReaded, refetch, subscribeToMoreNotification } =
    useNotificationQuery();
  const { readAllNotifs } = useMutationNotifcation();
  const { labels, lang } = useTranslate();
  React.useEffect(() => {
    subscribeToMoreNotification();
  }, []);
  React.useEffect(() => {
    refetch();
  }, [lang]);
  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
        <DropdownToggle
          tag="a"
          className="nav-link nav-link-label"
          onClick={() => readAllNotifs()}
        >
          <Icon.Bell size={21} />
          {inReaded > 0 && (
            <Badge pill color="primary" className="badge-up">
              <span className="text-white">{inReaded}</span>
            </Badge>
          )}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              {inReaded > 0 && (
                <h3 className="text-white">
                  {inReaded} {labels?.pages?.notification?.new}
                </h3>
              )}
              <span className="notification-title">
                {labels?.pages?.notification?.notifications}
              </span>
            </div>
          </li>
          {notifications?.length > 0 ? (
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {notifications?.map((item) => (
                <NotificationItem key={item?.id} {...item} />
              ))}
            </PerfectScrollbar>
          ) : (
            ""
          )}
          <li className="dropdown-menu-footer">
            <DropdownItem tag="a" className="p-1 text-center">
              <Link to="/notification-history" className="align-middle">
                {labels?.pages?.notification?.readAllNotifications}
              </Link>
            </DropdownItem>
          </li>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
