import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";

const DELIVERY_POINT = gql`
  query getDeliveryPoint($id: ID) {
    getDeliveryPoint(id: $id) {
      id
      name
      address
      description
      priceBy
      delivrable
      country {
        id
        name
      }
      type
      price
      blocked
    }
  }
`;
const ADD_DELIVERY_POINT = gql`
  mutation addDeliveryPoint($input: DeliveryPointUpdateInput) {
    addDeliveryPoint(input: $input) {
      id
      name
      address
      description
      delivrable
      priceBy
      country {
        id
        name
      }
      type
      price
      blocked
    }
  }
`;
const UPDATE_DELIVERY_POINT = gql`
  mutation updateDeliveryPoint($id: ID!, $input: DeliveryPointUpdateInput) {
    updateDeliveryPoint(id: $id, input: $input) {
      id
      name
      address
      description
      delivrable
      priceBy
      country {
        id
        name
      }
      type
      price
      blocked
    }
  }
`;
const REMOVE_DELIVERY_POINT = gql`
  mutation removeDeliveryPoint($id: String) {
    removeDeliveryPoint(id: $id)
  }
`;
const BLOCK_DELIVERY_POINT = gql`
  mutation blockDeliveryPoint($id: String, $blocked: Boolean) {
    blockDeliveryPoint(id: $id, blocked: $blocked)
  }
`;
export const useMutationDeliveryPoint = () => {
  const [update] = useMutation(UPDATE_DELIVERY_POINT);
  const [add] = useMutation(ADD_DELIVERY_POINT);
  const [block] = useMutation(BLOCK_DELIVERY_POINT);
  const [remove] = useMutation(REMOVE_DELIVERY_POINT);

  function updateDeliveryPoint({ id, input }) {
    return update({ variables: { id, input }, fetchPolicy: "no-cache" });
  }

  function addDeliveryPoint({ input }) {
    return add({ variables: { input }, fetchPolicy: "no-cache" });
  }
  function blockDeliveryPoint({ id, blocked }) {
    return block({ variables: { id, blocked }, fetchPolicy: "no-cache" });
  }
  function removeDeliveryPoint({ id }) {
    return remove({ variables: { id }, fetchPolicy: "no-cache" });
  }
  return {
    updateDeliveryPoint,
    addDeliveryPoint,
    blockDeliveryPoint,
    removeDeliveryPoint,
  };
};

export const useQueryDeliveryPoint = (id) => {
  const { data, ...rest } = useQuery(DELIVERY_POINT, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  return { data: data?.getDeliveryPoint, ...rest };
};
