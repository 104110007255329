import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";

const GET_MENTION_LEGALS = gql`
  query getMentionLegals($target: EnumMentionLegalTarget) {
    getMentionLegals(target: $target) {
      nodes {
        id
        title
        content
        status
      }
    }
  }
`;
const GET_MENTION_LEGALS_APP = gql`
  query {
    getMentionLegalsApp {
      nodes {
        id
        title
        content
        status
      }
    }
  }
`;
const GET_FAQS = gql`
  query getFAQS($limit: Int, $skip: Int, $ref: FAQEnum) {
    getFAQS(limit: $limit, skip: $skip, ref: $ref) {
      nodes {
        id
        title
        content
        category {
          id
          title
        }
      }
    }
  }
`;
const GET_FAQS_CAT = gql`
  query getFaqsCategories($status: Boolean) {
    getFaqsCategories(status: $status) {
      id
      title
    }
  }
`;
const GET_EXTERNAL_LINKS = gql`
  query getExternalLinks($limit: Int, $skip: Int, $type: String) {
    getExternalLinks(limit: $limit, skip: $skip, type: $type) {
      nodes {
        id
        title
        image
        link
        type
      }
    }
  }
`;

const ADD_FAQ = gql`
  mutation addFAQ($input: FAQInput, $ref: FAQEnum) {
    addFAQ(input: $input, ref: $ref) {
      id
      title
      content
    }
  }
`;
const UPDATE_FAQ = gql`
  mutation updateFAQ($id: ID, $input: FAQInput, $ref: FAQEnum) {
    updateFAQ(id: $id, input: $input, ref: $ref) {
      id
      title
      content
    }
  }
`;
const DELETE_FAQ = gql`
  mutation deleteFAQ($id: ID, $ref: FAQEnum) {
    deleteFAQ(id: $id, ref: $ref)
  }
`;
const SORT_FAQ = gql`
  mutation sortFAQS($ref: FAQEnum, $input: [SortInputFAQ]) {
    sortFAQS(input: $input, ref: $ref) {
      id
    }
  }
`;
const UPDATE_MENTION_LEGAL = gql`
  mutation updateMentionLegal($id: ID, $input: MentionLegalInput) {
    updateMentionLegal(id: $id, input: $input) {
      id
    }
  }
`;
const ENABLE_MENTION_LEGAL = gql`
  mutation enableMentionLegal($id: ID) {
    enableMentionLegal(id: $id) {
      id
      title
      status
    }
  }
`;

const ADD_EXTERNAL_LINK = gql`
  mutation addExternalLink($input: externalLinkInput) {
    addExternalLink(input: $input) {
      title
      image
      link
      type
    }
  }
`;

const UPDATE_EXTERNAL_LINK = gql`
  mutation updateExternalLink($id: ID, $input: externalLinkInput) {
    updateExternalLink(id: $id, input: $input) {
      id
      title
      image
      link
      type
    }
  }
`;

const DELETE_EXTERNAL_LINK = gql`
  mutation deleteExternalLink($id: ID) {
    deleteExternalLink(id: $id)
  }
`;

export const useMutationExternalLink = () => {
  const [addLink] = useMutation(ADD_EXTERNAL_LINK);
  const [updateLink] = useMutation(UPDATE_EXTERNAL_LINK);
  const [delLink] = useMutation(DELETE_EXTERNAL_LINK);
  function addExternals(input) {
    return addLink({
      variables: {
        input,
      },
    });
  }

  function updateExternals({ id, input }) {
    return updateLink({
      variables: {
        input,
        id,
      },
    });
  }
  function deleteExternals({ id }) {
    return delLink({
      variables: {
        id,
      },
    });
  }
  return {
    addExternals,
    updateExternals,
    deleteExternals,
  };
};
export const useMutationFAQ = () => {
  const [add] = useMutation(ADD_FAQ);
  const [update] = useMutation(UPDATE_FAQ);
  const [del] = useMutation(DELETE_FAQ);
  const [sort] = useMutation(SORT_FAQ);
  function addFAQS({ input, ref }) {
    return add({
      variables: {
        input,
        ref,
      },
    });
  }

  function updateFAQS({ id, input, ref }) {
    return update({
      variables: {
        input,
        id,
        ref,
      },
    });
  }
  function deleteFAQS({ id, ref }) {
    return del({
      variables: {
        id,
        ref,
      },
    });
  }
  function sortFAQS({ input, ref }) {
    return sort({
      variables: {
        input,
        ref,
      },
    });
  }
  return {
    addFAQS,
    updateFAQS,
    deleteFAQS,
    sortFAQS,
  };
};
export const useMutationMentionLegal = () => {
  const [update] = useMutation(UPDATE_MENTION_LEGAL);
  const [enable] = useMutation(ENABLE_MENTION_LEGAL);
  function updateMentionLegal({ id, input }) {
    return update({
      variables: {
        id,
        input,
      },
    });
  }
  function enableMentionLegal({ id }) {
    return enable({
      variables: {
        id,
      },
      update: (cache, { data }) => {},
    });
  }
  return { updateMentionLegal, enableMentionLegal };
};
export const useQueryMentionLegals = (target) => {
  const { data, ...rest } = useQuery(GET_MENTION_LEGALS, {
    variables: {
      target,
    },
  });
  return {
    data: data?.getMentionLegals,
    ...rest,
  };
};
export const useQueryMentionLegalsApp = () => {
  const { data, ...rest } = useQuery(GET_MENTION_LEGALS_APP);
  return {
    data: data?.getMentionLegalsApp,
    ...rest,
  };
};
export const useQueryFAQS = ({ ref }) => {
  const { data, ...rest } = useQuery(GET_FAQS, {
    variables: {
      ref,
    },
  });
  return {
    data: data?.getFAQS,
    ...rest,
  };
};
export const useQueryFAQSCat = (status) => {
  const { data, ...rest } = useQuery(GET_FAQS_CAT, {
    variables: {
      status,
    },
  });
  return {
    getFaqsCategories: data?.getFaqsCategories,
    ...rest,
  };
};
export const useQueryExternalLinks = (type) => {
  const { data, ...rest } = useQuery(GET_EXTERNAL_LINKS, {
    variables: {
      type,
    },
  });
  return {
    data: data?.getExternalLinks,
    ...rest,
  };
};
