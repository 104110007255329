import {
  MY_PROFILe,
  UPDATE_MY_PROFILE,
  UPDATE_MY_SETTING,
  ME,
  UPDATE_PASSWORD,
  ME_ADMIN,
  UPDATE_ADMIN,
} from "graphql/tags";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import {
  GET_APP_SETTINGS,
  UPDATE_APP_LINK_STORE_SETTINGS,
  UPDATE_CONTACT_SUPPORT_SETTINGS,
  UPDATE_ONE_SIGNAL_SETTINGS,
  UPDATE_TEMPLATE_MAIL_SETTINGS,
} from "graphql/tags/settings.tag";
import { isAdmin } from "configs/function";

function updateCacheSettings({ cache, data }) {
  const key = Object.keys(data)[0];
  const settings = cache.readQuery({ query: GET_APP_SETTINGS });
  cache.writeQuery({
    query: GET_APP_SETTINGS,
    data: {
      ...settings,
      getSettings: {
        ...settings?.getSettings,
        ...data[key],
      },
    },
  });
}
const CLIENT = gql`
  query getClient($id: ID) {
    getClient(id: $id) {
      id
      firstName
      lastName
      fullName
      tel
      address
      compayName
      photoUrl
      profile {
        email
        id
      }
    }
  }
`;
const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $input: ClientUpdateInput) {
    updateClient(id: $id, input: $input) {
      id
      firstName
      lastName
      fullName
      tel
      address
      profile {
        email
      }
    }
  }
`;
const ACCEPT_MENTION_LEGAL = gql`
  mutation {
    acceptMentionLegal
  }
`;
export const useMutationClient = () => {
  const [update] = useMutation(UPDATE_CLIENT);
  function updateClient({ id, input }) {
    return update({ variables: { id, input }, fetchPolicy: "no-cache" });
  }
  return { updateClient };
};
export const useQueryClient = (id) => {
  const { data, ...rest } = useQuery(CLIENT, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  return { data: data?.getClient, ...rest };
};

export const useQueryMe = () => {
  const { data, loading } = useQuery(ME);
  return {
    data: data?.me,
    loading,
  };
};
const FEE_HOSTING_TO_PAY = gql`
  query getFlorist($id: ID) {
    getFlorist(id: $id) {
      id
      commercial
      feeHostingToPay {
        amount
        toPay
        solde
        acompte
        isAcompte
      }
      feeHosting {
        fullPaid
      }
    }
  }
`;
const FEE_SUBSCRIPTION_TO_PAY = gql`
  query getFlorist($id: ID) {
    getFlorist(id: $id) {
      id
      commercial
      feeSubscription {
        dueDate
      }
      subscriptionFeeToPay {
        amount
        nextDate
        nextDateText
        toPay
        amount
        proRata
        type
      }
    }
  }
`;
export const useQueryFeeHostingToPay = (id) => {
  const { data, loading, error } = useQuery(FEE_HOSTING_TO_PAY, {
    variables: { id },
  });

  return {
    data: data?.getFlorist,
    loading,
  };
};
export const useQueryFeeSubscriptionToPay = (id) => {
  const { data, loading, error } = useQuery(FEE_SUBSCRIPTION_TO_PAY, {
    variables: { id },
  });

  return {
    data: data?.getFlorist,
    loading,
  };
};
export const useQueryProfile = () => {
  const { data, ...others } = useQuery(MY_PROFILe);
  return {
    data: data?.myStore,
    ...others,
  };
};
export const useMutationProfile = () => {
  const [updateMyStore] = useMutation(UPDATE_MY_PROFILE);
  const [acceptMentionLegalM] = useMutation(ACCEPT_MENTION_LEGAL);
  function updateMyProfile({ input }) {
    return updateMyStore({
      variables: {
        input,
      },
      update: (cache, {}) => {},
    });
  }
  function acceptMentionLegal() {
    return acceptMentionLegalM({});
  }
  return { updateMyProfile, acceptMentionLegal };
};
export const useMutationSettings = () => {
  const [updateMyStoreSettings] = useMutation(UPDATE_MY_SETTING);
  function updateMyProfile({ input }) {
    return updateMyStoreSettings({
      variables: {
        input,
      },
      update: (cache, {}) => {},
    });
  }
  return { updateMyProfile };
};
export const useMutationUpdateMailTemplate = () => {
  const [update] = useMutation(UPDATE_TEMPLATE_MAIL_SETTINGS);
  function updateMailTemplate({ input }) {
    return update({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        updateCacheSettings({ cache, data });
      },
    });
  }
  return { updateMailTemplate };
};
export const useMutationUpdateOneSignalConfig = () => {
  const [update] = useMutation(UPDATE_ONE_SIGNAL_SETTINGS);
  function updateOneSignalConfig({ input }) {
    return update({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        updateCacheSettings({ cache, data });
      },
    });
  }
  return { updateOneSignalConfig };
};
export const useMutationUpdateSupportContact = () => {
  const [update] = useMutation(UPDATE_CONTACT_SUPPORT_SETTINGS);
  function updateContactSupportConfig({ input }) {
    return update({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        updateCacheSettings({ cache, data });
      },
    });
  }
  return { updateContactSupportConfig };
};
export const useMutationUpdateAppLink = () => {
  const [update] = useMutation(UPDATE_APP_LINK_STORE_SETTINGS);
  function updateAppLinkStore({ input }) {
    return update({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        if (isAdmin()) updateCacheSettings({ cache, data });
      },
    });
  }
  return { updateAppLinkStore };
};
export const useMutationUpdatePassword = () => {
  const [update] = useMutation(UPDATE_PASSWORD);
  function updatePassword({ input }) {
    return update({
      variables: {
        input,
      },
    });
  }
  return { updatePassword };
};
export const useQueryAdminAccount = () => {
  const { data, ...rest } = useQuery(ME_ADMIN);
  return { data: data?.getAdminAccount };
};
export const useMutationAdminAccount = () => {
  const [update] = useMutation(UPDATE_ADMIN);
  function updateAdminAccount({ input }) {
    return update({
      variables: {
        input,
      },
    });
  }
  return { updateAdminAccount };
};
