import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";

const GETS_COMMANDS_SHIP = gql`
  query getCommandsByCountryType($filter: ShipmentFilter) {
    getCommandsByCountryType(filter: $filter) {
      nodes {
        id
        num
        state
        deliveryAddress {
          country
        }
        items {
          _id
          variant {
            id
            price
            weight
            weightCarton
            depth
            height
            width
          }
          product {
            id
            translations {
              country
              name
              description
            }
          }
          quantity
        }
        itemsCount
        scannedCardbords
        florist {
          id
          name
        }
        client {
          lastName
          firstName
        }
        deliveryPoint {
          id
          name
          type
        }
      }
      count
      status
      rates
      commission
      type
      suppliers {
        id
        name
      }
      countries {
        id
        name
      }
    }
  }
`;

const SHIPMENT = gql`
  query getShipment($id: ID) {
    getShipment(id: $id) {
      id
      name
      description
      trackingUrl
      containerNum
      country {
        id
        name
      }
      state
      type
      origin
      destination
      eta
      ets
      commands {
        id
        num
        state
        commission
        rates
        deliveryAddress {
          country
        }
        items {
          _id
          variant {
            id
            price
            weight
            weightCarton
            depth
            height
            width
          }
          product {
            id
            translations {
              country
              name
              description
            }
          }
          quantity
        }
        itemsCount
        scannedCardbords
        client {
          lastName
          firstName
        }
        florist {
          id
          name
        }
      }
      blocked
    }
  }
`;
const ADD_SHIPMENT = gql`
  mutation addShipment($input: ShipmentUpdateInput) {
    addShipment(input: $input) {
      id
      name
      description
      state
      country {
        id
        name
      }
      type
      origin
      destination
      trackingUrl
      containerNum
      eta
      commands {
        id
        num
        client {
          lastName
          firstName
        }
        florist {
          id
          name
        }
      }
      blocked
    }
  }
`;
const UPDATE_SHIPMENT = gql`
  mutation updateShipment($id: ID!, $input: ShipmentUpdateInput) {
    updateShipment(id: $id, input: $input) {
      id
      name
      state
      description
      country {
        id
        name
      }
      trackingUrl
      containerNum
      type
      origin
      destination
      eta
      commands {
        id
        num
        client {
          lastName
          firstName
        }
        florist {
          id
          name
        }
      }
      blocked
    }
  }
`;
const REMOVE_SHIPMENT = gql`
  mutation removeShipment($id: String) {
    removeShipment(id: $id)
  }
`;
const BLOCK_SHIPMENT = gql`
  mutation blockShipment($id: String, $blocked: Boolean) {
    blockShipment(id: $id, blocked: $blocked)
  }
`;
const GET_SUPPLIERS = gql`
  query getSuppliers {
    getSuppliers {
      id
      name
      profile {
        id
      }
    }
  }
`;
export const useMutationShipment = () => {
  const [update] = useMutation(UPDATE_SHIPMENT);
  const [add] = useMutation(ADD_SHIPMENT);
  const [block] = useMutation(BLOCK_SHIPMENT);
  const [remove] = useMutation(REMOVE_SHIPMENT);

  function updateShipment({ id, input }) {
    return update({ variables: { id, input }, fetchPolicy: "no-cache" });
  }

  function addShipment({ input }) {
    return add({ variables: { input }, fetchPolicy: "no-cache" });
  }
  function blockShipment({ id, blocked }) {
    return block({ variables: { id, blocked }, fetchPolicy: "no-cache" });
  }
  function removeShipment({ id }) {
    return remove({ variables: { id }, fetchPolicy: "no-cache" });
  }
  return {
    updateShipment,
    addShipment,
    blockShipment,
    removeShipment,
  };
};

export const useQueryShipment = (id) => {
  const { data, ...rest } = useQuery(SHIPMENT, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  return { data: data?.getShipment, ...rest };
};
export const useQueryCommands = (filter) => {
  const { data, ...rest } = useQuery(GETS_COMMANDS_SHIP, {
    variables: {
      filter,
    },
    fetchPolicy: "no-cache",
  });

  return { data: data?.getCommandsByCountryType, ...rest };
};
export const useQuerySuppliers = () => {
  const { data, ...rest } = useQuery(GET_SUPPLIERS, {
    variables: {},
    fetchPolicy: "no-cache",
  });

  return { getSuppliers: data?.getSuppliers, ...rest };
};
