import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import classnames from "classnames";
import { useGlobalSettings } from "utility/context/Language";

const AppNameHeader = ({ collapsed, sidebarState }) => {
  const { settings } = useGlobalSettings();
  function getLogo() {
    if (collapsed && sidebarState) {
      return settings?.appResponsiveLogo;
    }
    return settings?.appLogo;
  }
  function getStyle() {
    if (collapsed && sidebarState) {
      return "logo-responsive";
    }
    return "logo";
  }
  return (
    <div>
      <NavLink to="/" className="navbar-brand">
        <img
          src={"https://api.btobturk.com/media/mentions/1688998840610.jpg"}
          alt="logo"
          className={getStyle()}
        />
        {/* <h2 className="brand-text mb-0">{settings?.appName}</h2> */}
      </NavLink>
    </div>
  );
};
class SidebarHeader extends Component {
  getStyle = () => {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
      sidebarState,
    } = this.props;

    return collapsed && sidebarState ? "" : "row-center";
  };
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
      sidebarState,
    } = this.props;

    return (
      <div className="navbar-header">
        <div className={this.getStyle()}>
          <AppNameHeader {...{ collapsed, sidebarState }} />
        </div>

        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
